<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center" style="">
      <v-col cols="12" md="4">
        <div class="center-logo">
          <app-logo></app-logo>
        </div>
        <v-card flat class="rounded-xl">
          <v-toolbar flat class="d-flex justify-center">
            <v-card-title class="bold-text">Select Account</v-card-title>
          </v-toolbar>
          <p class="w-auto pa-2">
            You have been added as a team member on an account, please select
            one below or skip to your dashboard
          </p>
          <v-form lazy-validation ref="form" class="text-left px-10 pb-7">
            <label>Select Account you want to access</label>
            <v-autocomplete
              solo
              flat
              dense
              rounded
              :rules="[(v) => !!v || 'Please select one account']"
              :items="accounts"
              v-model="form.user_id"
              class="rounded-xl"
              background-color="secondary"
              :loading="loadingAccounts"
              item-text="name"
              item-value="user_id"
            ></v-autocomplete>
          </v-form>
          <div class="justify-center d-flex pb-4">
            <v-btn
              text
              class="rounded-xl px-7 py-5 primary capitalize mr-3"
              @click="$refs.form.validate() ? selectAccount() : null"
              :loading="loading"
            >
              Continue
            </v-btn>
            <v-btn
              text
              class="rounded-xl px-7 py-5 secondary darken-2 capitalize"
              @click="$router.replace({ name: 'Dashboard' })"
              :loading="loading"
            >
              Skip
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppLogo from "../../components/Logo";
import { mapActions } from "vuex";
export default {
  name: "SelectAccount",
  components: {
    AppLogo,
  },
  data() {
    return {
      accounts: [],
      form: {},
      loading: false,
      loadingAccounts: false,
    };
  },
  created() {
    this.getAccounts();
  },
  methods: {
    ...mapActions({
      get_accounts: "teams/get_accounts",
      select_account: "auth/select_account",
    }),
    async getAccounts() {
      this.loadingAccounts = true;
      try {
        const response = await this.get_accounts();
        response.data.map((account) => {
          const tempAccount = {};
          tempAccount["user_id"] = account["user_id"];
          tempAccount["name"] = account["user"]["name"];

          this.accounts.push(tempAccount);
        });
        this.loadingAccounts = false;
      } catch (e) {
        this.loadingAccounts = false;
        console.log(e);
      }
    },
    async selectAccount() {
      this.loading = true;
      try {
        await this.select_account(this.form);
        this.loading = false;
        await this.$router.replace({ name: "Dashboard" });
      } catch (e) {
        this.loading = false;
        this.$response.sendError(e);
      }
    },
  },
};
</script>

<style scoped></style>
